<script setup lang="ts">
import { ref, computed } from 'vue'
import {getNavbar} from "~/utils/base/common-utils";

const route = useRoute()
const navbar = ref(getNavbar())

const formatedNav = computed(() => {
  return navbar.value.flatMap((item: any) => {
    return item.options.length ? item.options : item
  })
})

const activeIndex = ref(0)

const updateActiveIndex = () => {
  const index = formatedNav.value.findIndex((nav: any) => nav.slug === route.path)
  activeIndex.value = index !== -1 ? index : 0
}

watch(
    () => route.path,
    () => {
      updateActiveIndex()
    },
    { immediate: true }
)
</script>

<template>
  <div class="w-full flex flex-col border border-gray-200 rounded-xl divide-y divide-gray-200">
    <ul class="w-full flex justify-center items-center">
      <li
          v-for="(nav, i) in formatedNav"
          :key="i"
          class="relative px-5 py-2.5"
      >
        <u-button
            :to="nav.slug"
            :disabled="i"
            color="white"
            variant="soft"
            :ui="{base: 'text-gray-800 hover:text-primary transition-colors duration-400'}"
            :class="{'text-primary': activeIndex === i}"
            :label="nav.fa_name"
        />

        <div
            v-if="activeIndex === i"
            class="absolute bottom-0 left-0 right-0 h-[2px] bg-primary rounded-full transition-all duration-300"
        ></div>
      </li>
    </ul>
    <slot />
  </div>
</template>
